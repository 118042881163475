import React, { useContext, useState } from "react";
import { Header as GrommetHeader, ResponsiveContext } from "grommet";

import SoftwareServices from "./SoftwareServices";
import { AuthContext } from "../Auth/authContext";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

interface HeaderProps {
  fullWidth?: Boolean;
  noMenu?: Boolean;
}

const DefaultHeader: React.FC<HeaderProps> = (props) => {
  const { fullWidth, noMenu } = props;
  const size = useContext(ResponsiveContext);
  const auth = useContext(AuthContext);
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);
  const [openMenu, setOpenMenu] = useState(false);

  const menuHandleClick = () => {
    setOpenMenu(!openMenu);
  };

  const closeMobileMenu = () => {
    setOpenMenu(false);
  };

  return <SoftwareServices />;
};

export default DefaultHeader;
