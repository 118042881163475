import { hpe } from 'grommet-theme-hpe'
import { deepMerge } from 'grommet/utils';

const customTheme = {
  global: {
    colors: {
      brand: "#F9D905",
      focus: 'brand',
      header: '#ffffff',
      footer: '#ffffff',
      green: {
        dark: '#008567',
        light: '#F9D905',
      },
    },
  },
  button: {
    primary: {
      background: { color: 'brand' },
      color: 'dark-1',
      font: { weight: 'bold' },
    },
  },
  checkBox: {
    check: {
      extend: ({ theme, checked }) => `
        ${checked && `background-color: #2066df`};
        box-shadow: none;
        border: solid 2px #e3e4e6;
        `,
      boxShadow: 'none'
    },
    hover: {
      border: {
        color: undefined,
      },
    },
    icon: {
      size: '18px',
      extend: 'stroke: white;',
    },
    color: {
      light: 'red',
      dark: 'yellow',
    },
  }
}

const theme = deepMerge(hpe, customTheme)

export default theme
